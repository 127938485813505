<template>
  <div>
    <hero-bar>
      Clientes
      <router-link slot="right" :to="{ name:'cliente.new' }" class="button is-primary">
        Novo cliente
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="buscaCliente">
        <div class="columns">
          <div class="column is-3">
            <b-field class="mb-3" label="Busca">
              <b-input v-model="valorBusca"></b-input>
            </b-field>
          </div>
          <div class="column is-flex is-align-items-self-end">
            <b-field class="mb-3 ml-2">
              <b-button type="is-primary" @click="buscaCliente">Buscar</b-button>
            </b-field>
            <b-field class="mb-3 ml-2">
              <b-button @click="limpabusca">Limpar busca</b-button>
            </b-field>
          </div>
        </div>
      </form>

      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Clients"
        icon="account-multiple"
      >
        <!-- <clients-table-sample
          :data-url="`${$router.options.base}data-sources/clients.json`"
          :checkable="true"
        /> -->
        <b-field class="m-3">
          <b-select v-model="pagination.perPage" @input="getClientes">
            <option :value="15">15 por página</option>
            <option :value="25">25 por página</option>
            <option :value="50">50 por página</option>
          </b-select>
       </b-field>

        <b-table
          :data="clientList"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
          :backend-pagination="true"
          :total="pagination.total"
          :striped="true"
          :hoverable="true"
          :loading="isLoading"
          :paginated="true"
        >
          <b-table-column label="ID" field="ID" v-slot="props">
            {{ props.row.IDCliente }}
          </b-table-column>
          <b-table-column label="Nome" field="nome" v-slot="props">
            {{ props.row.Name }}
          </b-table-column>
          <b-table-column label="Email" field="email" v-slot="props">
            {{ props.row.Email }}
          </b-table-column>
          <b-table-column label="Telefone" field="telefone" v-slot="props">
            {{ props.row.NumberPhone }}
          </b-table-column>
          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <router-link :to="{ name:'cliente.detalhes', params: {id: props.row.IDCliente} }" class="button is-small is-primary">
                <b-icon icon="arrow-right" size="is-small"/>
              </router-link>
              <!-- <b-button @click="confirmDelete({ id: props.row.IDCliente, name: props.row.Name })" class="button is-small is-danger">
                <b-icon icon="delete" size="is-small"/>
              </b-button> -->
            </div>
          </b-table-column>
          <template v-if="!isLoading" #empty>Nenhum Cliente encontrado</template>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import {
  getClientes,
  searchCliente,
  notificationError,
  formatPaginationObj
} from '@/core'

export default {
  name: 'Tables',
  components: {
    HeroBar,
    CardComponent
  },
  computed: {
    titleStack () {
      return ['Admin', 'Clientes']
    }
  },
  data () {
    return {
      clientList: [],
      isLoading: true,
      paginated: false,
      isModalOpen: false,
      data: {},
      pagination: formatPaginationObj(),
      ListaCNPJs: [
        {
          Descricao: null,
          CNPJ: null
        }
      ],
      valorBusca: null
    }
  },
  methods: {
    getClientes () {
      this.isLoading = true
      getClientes(this.pagination.currentPage, this.pagination.perPage)
        .then(res => {
          this.isLoading = false
          this.clientList = res.dados
          this.pagination = formatPaginationObj(res)
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    buscaCliente () {
      if (this.valorBusca !== null && this.valorBusca.length) {
        this.isLoading = true
        searchCliente(this.valorBusca)
          .then(res => {
            this.clientList = res.data.dados
            this.pagination = formatPaginationObj(res.data)
            this.isLoading = false
          })
          .catch(() => {
            this.clientList = []
            this.isLoading = false
            this.pagination = formatPaginationObj()
          })
      } else {
        this.getClientes()
      }
    },
    limpabusca () {
      this.valorBusca = null
      this.pagination = formatPaginationObj()
      this.getClientes()
    }
  },
  created () {
    this.getClientes()
  }
}
</script>
